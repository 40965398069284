<template>
  <div class="score-chart">
    <!-- Header -->
    <div
      slot="header"
      class="score-chart-header card-title"
    >
      <!-- Title -->
      <span
        class="m-0"
        style="font-size: 0.82rem;"
        :style="{ '-webkit-font-smoothing': 'antialiased' }"
      >
        {{ title }}
      </span>
    </div>

    <div class="score-chart-content">
      <div class="score-meter">
        <section class="vs-chip-danger bg-chip-transparent">
          <vs-chip
            v-for="n in 6"
            :key="n"
            color="danger"
          >
            <small><strong>{{ n }}</strong></small>
          </vs-chip>
        </section>

        <section class="vs-chip-warning bg-chip-transparent">
          <vs-chip
            v-for="n in 2"
            :key="n + 6"
            color="warning"
          >
            <small><strong>{{ n + 6 }}</strong></small>
          </vs-chip>
        </section>

        <section class="vs-chip-success bg-chip-transparent">
          <vs-chip
            v-for="n in 2"
            :key="n + 8"
            color="success"
          >
            <small><strong>{{ n + 8 }}</strong></small>
          </vs-chip>
        </section>
      </div>

      <div class="score-chart-content--donut">
        <div
          v-if="scoreDesc && scoreDesc.length > 0"
          class="total-score"
        >
          <h2>{{ totalScore }}</h2>
          <small>{{ scoreDesc }}</small>
        </div>
        <div
          v-else
          class="total-score--solo"
        >
          <h2>{{ totalScore }}</h2>
        </div>

        <vue-apex-charts
          type="donut"
          height="185"
          :options="chartOptions"
          :series="series"
        />
      </div>

      <div class="score-chart-content--list">
        <div class="score-list-item">
          <vs-chip
            class="c-vs-chip pill-3"
            color="danger"
            transparent
          >
            {{ data[2].text || 'Detratores' }}
          </vs-chip>

          <vs-chip
            class="c-vs-chip pill-1"
            color="danger"
          >
            {{ data[2].value }}
          </vs-chip>

          <vs-chip class="c-vs-chip pill-2">
            {{ data[2].perc | formatNumber }}%
          </vs-chip>
        </div>

        <div class="score-list-item">
          <vs-chip
            class="c-vs-chip pill-3"
            color="warning"
            transparent
          >
            {{ data[1].text || 'Neutros' }}
          </vs-chip>

          <vs-chip
            class="c-vs-chip pill-1"
            color="warning"
          >
            {{ data[1].value }}
          </vs-chip>

          <vs-chip class="c-vs-chip pill-2">
            {{ data[1].perc | formatNumber }}%
          </vs-chip>
        </div>

        <div class="score-list-item">
          <vs-chip
            class="c-vs-chip pill-3"
            color="success"
            transparent
          >
            {{ data[0].text || 'Promotores' }}
          </vs-chip>

          <vs-chip
            class="c-vs-chip pill-1"
            color="success"
          >
            {{ data[0].value }}
          </vs-chip>

          <vs-chip class="c-vs-chip pill-2">
            {{ data[0].perc | formatNumber }}%
          </vs-chip>
        </div>

        <footer class="score-list-item">
          <vs-chip class="c-vs-chip pill-3">
            <strong>Total:</strong>
          </vs-chip>

          <vs-chip
            class="c-vs-chip pill-1"
            color="dark"
          >
            <strong>{{ totalSum }}</strong>
          </vs-chip>

          <vs-chip class="c-vs-chip pill-2">
            100%
          </vs-chip>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
/* Components */
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'ScoreChart',
  components: { VueApexCharts },
  props: {
    data: {
      type: Array,
      required: true,
    },
    scoreDesc: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: true,
    },
    totalScore: {
      type: Number,
      required: true,
    },
  },
  computed: {
    /* Theme */
    totalSum() {
      if (this.data.length === 0) return 0;

      const sum = this.data.reduce((acc, currentValue) => {
        return acc + Number(currentValue.value);
      }, 0);

      return sum;
    },
    /* Chart */
    chartOptions() {
      return {
        labels: this.data.map((item) => item.text),
        dataLabels: {
          enabled: false,
        },
        grid: {
          padding: {
            left: 0,
            right: 0,
          },
          borderColor: 'rgba(0, 0, 0, 0.1)',
        },
        plotOptions: {
          pie: {
            donut: {
              size: '70px',
            },
          },
        },
        stroke: {
          width: 0,
        },
        legend: {
          show: false,
        },
        colors: [
          '#36BEA6',
          '#FF8200',
          '#F62D51',
        ],
        tooltip: {
          fillSeriesColor: false,
        },
      };
    },
    series() {
      return this.data.map((item) => item.value);
    },
  },
};
</script>

<style scoped lang="scss">
.score-chart {
  background-color: white;
  border: 0;
  border-radius: .3rem;
  display: flex;
  flex-flow: column nowrap;
  height: 500px;
  margin-bottom: 1rem;

  .score-chart-header {
    align-items: center;
    background-color: rgba(0, 0, 0, .64);
    border: 0;
    border-radius: .3rem .3rem 0 0;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    justify-content: space-between;
    max-height: 55.05px;
    padding: 1rem;

    span {
      color: #FAFAFA;
    }
  }

  .score-chart-content {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    gap: 1rem;
    height: 100%;
    overflow: auto;
    padding: 1rem;
    overflow: hidden;

    .score-chart-content--list {
      display: flex;
      flex-flow: column nowrap;
      gap: 0.5rem;
      width: 100%;

      .score-list-item {
        align-items: center;
        display: flex;
        gap: 0.15rem;
        justify-content: center;
        width: 100%;

        .pill-1 {
          width: 40px;
        }

        .pill-2 {
          width: 60px;
        }

        .pill-3 {
          width: 90px;
        }
      }
    }
  }
}

.c-vs-chip {
  font-size: 0.9em;
}

.score-meter {
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  gap: 0.35rem;

  section {
    align-items: center;
    border-radius: 25px;
    display: flex;
    flex-flow: row nowrap;
    gap: 0.35rem;
  }
}
</style>

<style lang="scss">
.score-meter section .con-vs-chip {
  margin-bottom: 0;
  min-height: 25px;
}

.score-chart-content--donut {
  position: relative;
}

.score-chart-content--donut {
  .total-score {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: calc(50% - 100px);
    left: calc(50% - 150px);
    z-index: 9999;

    small {
      color: #666;
    }
  }

  .total-score--solo {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    position: absolute;
    height: 100%;
    width: 100%;
    top: calc(50% - 96px);
    left: calc(50% - 150px);
    z-index: 9999;
  }
}
</style>
