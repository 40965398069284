<template>
  <div>
    <vs-button
      class="mb-3 float-right"
      style="z-index: 1"
      @click="printSurvey"
    >
      Imprimir Completo
    </vs-button>

    <div id="print">
      <img
        :src="imgpath(proj)"
        height="30"
        style="margin-left:10px; margin-bottom:10px;"
      >

      <img
        :src="imgpath('0')"
        height="25"
        style="margin-left:30px; margin-bottom:10px;"
      >

      <h5 style="font-size:1rem; margin-bottom:13px;">
        Nome do questionário: {{ surveyName }} - {{ '(' + surveyId + ')' }}
      </h5>

      <div
        v-for="(chapter, chapterIdx) in survey"
        :key="chapterIdx"
      >
        <h1 style="font-size:1.3rem; margin-bottom:15px;">
          {{ chapter.chapterTitle }}
        </h1>

        <div
          v-for="indicator in chapter.indicators"
          :key="indicator.title"
        >
          <h3
            slot="header"
            class="card-title mb-2 req"
            style="font-size:1rem;"
          >
            {{ indicator.title }}
          </h3>

          <ul class="values-list">
            <li
              v-for="(values, valueIdx) in indicator.values"
              :key="valueIdx"
            >
              <div class="values-list-item">
                <span>{{ values.text }} - {{ values.value }} | {{ values.perc }}%</span>
              </div>
            </li>
          </ul>

          <footer
            v-if="indicator.type === 'NPS'"
            class="values-list__footer"
          >
            <span>Pontuação: {{ indicator.score }} ({{ indicator.scoreDesc }})</span>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* Config */
import { configDomain } from '@/global';

export default {
  name: 'PrintComments',
  props: {
    survey: {
      type: Object,
      default: () => ({})
    },
    surveyId: {
      type: Number,
      default: 0,
    },
    surveyName: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    proj: {},
  }),
  created() {
    var ret = this.$store.state.surveyStats;
    this.proj = ret.surveyInf.project;
  },
  methods: {
    printSurvey() {
      var printContents = document.getElementById('print').innerHTML;

      var winPrint = window.open('', '', 'left=0,top=0,width=800,height=600,toolbar=0,scrollbars=0,status=0');

      winPrint.document.body.innerHTML = printContents
      winPrint.document.body.style = 'font-family: "Anek Malayalam", sans-serif; font-size: 12px'
      winPrint.document.close();
      winPrint.focus();
      winPrint.print();
      winPrint.close();
    },
    imgpath(id) {
      return `${configDomain}/prj/${id}/logo.png`;
    }
  }
}
</script>

<style scoped lang="scss">
.values-list {
  list-style: none;
}

.values-list-item {
  display: flex;

  span {
    margin-bottom: 0.15rem;
  }
}

.values-list__footer {
  margin-bottom: 1rem;
  margin-top: 0.75rem;
}

.values-list,
.values-list__footer {
  margin-left: 1rem;
}
</style>
