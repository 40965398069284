<template>
  <div>
    <!-- Export Survey Button -->
    <div class="d-flex align-items-stretch justify-content-stretch">
      <vs-button
        color="secondary"
        :disabled="disabled"
        class="text-dark btnx"
        href.prevent
        icon="file_download"
      >
        Exportar / Imprimir
      </vs-button>

      <vs-dropdown>
        <vs-button
          class="text-dark btn-drop"
          color="secondary"
          :disabled="disabled"
          icon="expand_more"
          type="filled"
        />

        <vs-dropdown-menu
          v-if="!disabled"
          id="custom-dropdown-menu"
        >
          <vs-dropdown-group vs-label="PDF">
            <vs-dropdown-item class="p-0 b-0">
              <vs-button
                class="p-2 by-1 w-100 h-100 text-left"
                :disabled="disabled"
                type="flat"
                @click="popupViewQuestionPrint = true"
              >
                Capitulo
              </vs-button>
            </vs-dropdown-item>

            <vs-dropdown-item class="p-0 b-0">
              <vs-button
                class="p-2 by-1 w-100 h-100 text-left"
                :disabled="disabled"
                type="flat"
                @click="popupViewSurveyPrint = true"
              >
                Completo
              </vs-button>
            </vs-dropdown-item>

            <vs-dropdown-item class="p-0 b-0">
              <vs-button
                class="p-2 by-1 w-100 h-100 text-left"
                :disabled="disabled"
                type="flat"
                @click="popupViewSurveyPrintComments = true"
              >
                Completo com comentários
              </vs-button>
            </vs-dropdown-item>
          </vs-dropdown-group>

          <vs-dropdown-group vs-label="CSV">
            <vs-dropdown-item class="p-0 b-0">
              <vs-button
                class="p-2 by-1 w-100 h-100 text-left"
                :disabled="disabled"
                type="flat"
                @click="getCSVUrl"
              >
                Completo
              </vs-button>
            </vs-dropdown-item>
          </vs-dropdown-group>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>

    <!-- Popups -->
    <vs-popup
      :active.sync="popupViewQuestionPrint"
      title="Imprimir Capitulo"
      fullscreen
    >
      <PrintCap
        v-if="popupViewQuestionPrint"
        :project-id="projectId"
        :selected="selectedChapter"
        :survey="surveyResult"
        :survey-id="surveyId"
        :survey-name="surveyName"
      />
    </vs-popup>

    <vs-popup
      :active.sync="popupViewSurveyPrint"
      title="Imprimir Pesquisa"
      fullscreen
    >
      <PrintSearch
        v-if="popupViewSurveyPrint"
        :project-id="projectId"
        :survey="surveyResult"
        :survey-id="surveyId"
        :survey-name="surveyName"
      />
    </vs-popup>

    <vs-popup
      :active.sync="popupViewSurveyPrintComments"
      title="Imprimir Completo"
      fullscreen
    >
      <PrintComments
        v-if="popupViewSurveyPrintComments"
        :project-id="projectId"
        :survey="surveyResultFull"
        :survey-id="surveyId"
        :survey-name="surveyName"
      />
    </vs-popup>
  </div>
</template>

<script>
/* Components */
import PrintCap from '../../dashboard-components/survey-structure/PrintBoxes';
import PrintSearch from '../../dashboard-components/survey-structure/PrintAllBoxes';
import PrintComments from '../../dashboard-components/survey-structure/PrintAllWithComments';

/* Services */
import Survey from '@/services/survey';

const surveyService = new Survey();

/* Style */
import 'vue-json-viewer/style.css';

export default {
  name: 'ExportSurvey',
  components: {
    PrintCap,
    PrintSearch,
    PrintComments,
  },
  props: {
    surveyResult: {
      type: Array,
      required: true,
    },
    projectId: {
      type: Number,
      required: true
    },
    selectedChapter: {
      type: Number,
      required: true,
    },
    surveyId: {
      type: Number,
      required: true
    },
    surveyName: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    surveyResultFull: [],
    /* Popups */
    popupViewQuestionPrint: false,
    popupViewSurveyPrint: false,
    popupViewSurveyPrintComments: false,
  }),
  computed: {
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  mounted() {
    this.getSurveyResultFull();
  },
  methods: {
    /* API */
    getCSVUrl() {
      this.$store.dispatch('set_isLoadingActive', true);

      surveyService
        .getSurveyExportCSV(this.surveyId, this.token)
        .then((resp) => this.downloadCSV(resp.data))
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    getSurveyResultFull() {
      this.$store.dispatch('set_isLoadingActive', true);

      surveyService.getSurveyResultFull(this.surveyId, this.token)
        .then((resp) => (this.surveyResultFull = resp))
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Helpers */
    getCurrentDate() {
      const padTo2Digits = (num) => num.toString().padStart(2, '0');

      let now = new Date();

      return [
        padTo2Digits(now.getDate()),
        padTo2Digits(now.getMonth() + 1),
        now.getFullYear(),
      ].join('_');
    },
    downloadCSV(content) {
      const csvData = new Blob([content], { type: 'text/csv' });
      const csvURL = URL.createObjectURL(csvData);
      const link = document.createElement('a');
      const fileName = `export-${this.surveyName}-${this.getCurrentDate()}`;

      link.href = csvURL;
      link.setAttribute('download', fileName);
      link.click();
    },
  },
};
</script>


<style scoped>
/* Custom Buttons */
.btnx {
  border-radius: 5px 0px 0px 5px;
  margin-left: 10px !important;
  max-height: 38px;
}

.btn-drop {
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0px 5px 5px 0px;
}
</style>

<style>
/* Custom Menu */
#custom-dropdown-menu a.vs-dropdown--item-link {
  min-width: 195px !important;
  padding: 0 !important;
}
</style>
