<template>
  <div>
    <main>
      <header class="d-flex align-items-center justify-content-between mt-3">
        <h2 class="text-white">
          {{ surveyName }}
        </h2>

        <ExportSurvey
          :disabled="false"
          :project-id="projectId"
          :selected-chapter="selectedChapter"
          :survey-id="surveyId"
          :survey-name="surveyName"
          :survey-result="surveyResult"
        />
      </header>

      <!-- Chapter Tabs -->
      <vs-tabs
        v-if="surveyResult.length > 0"
        class="custom-tabs"
        color="secondary"
      >
        <vs-tab
          v-for="(chapter, chapterIdx) in surveyResult"
          :key="chapterIdx"
          :label="replaceTitleTokens(chapter.chapterTitle)"
          @click="selectedChapter = chapterIdx"
        >
          <!-- Charts -->
          <vs-row class="mt-4">
            <vs-col
              v-for="indicator in chapter.indicators"
              :key="indicator.indId"
              vs-lg="4"
            >
              <BarChart
                v-if="indicator.type === 'Lista'"
                :key="indicator.code"
                :data="indicator.values"
                :title="replaceTitleTokens(indicator.title)"
                :indicator="indicator.code"
                type="alt"
              />

              <LogoChart
                v-if="indicator.type === 'Browse'"
                :key="indicator.code"
                :data="indicator.values"
                :title="replaceTitleTokens(indicator.title)"
                :indicator="indicator.code"
                type="alt"
              />

              <ScoreChart
                v-if="indicator.type === 'NPS'"
                :key="indicator.code"
                :data="indicator.values"
                :score-desc="indicator.scoreDesc"
                :title="replaceTitleTokens(indicator.title)"
                :total-score="indicator.score"
              />
            </vs-col>
          </vs-row>
        </vs-tab>
      </vs-tabs>
    </main>
  </div>
</template>

<script>
/* Components */
import ExportSurvey from './ExportSurvey.vue';
import BarChart from '../../dashboard-components/box-fanbase/BarChart.vue';
import LogoChart from '../../dashboard-components/box-fanbase/LogoChart.vue';
import ScoreChart from '../../dashboard-components/box-fanbase/ScoreChart.vue';

/* Services */
import Survey from '@/services/survey';

const surveyService = new Survey();

/* Style */
import 'vue-json-viewer/style.css';

export default {
  name: 'SurveyResult',
  components: {
    ExportSurvey,
    BarChart,
    LogoChart,
    ScoreChart,
  },
  props: {
    surveyId: {
      type: Number,
      required: true
    },
    surveyName: {
      type: String,
      required: true
    },
    projectId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    surveyResult: [],
    selectedChapter: 0,
  }),
  computed: {
    token() {
      const token = this.$store.state.accessToken.split('&');
      const res = token[0].replace('tk=', '');
      return res;
    },
  },
  created() {
    this.getSurveyResult();

    // Set survey project for "print popup" logo
    this.$store.commit('SET_surveyStats', {
      surveyInf: {
        project: this.projectId,
      },
    });
  },
  methods: {
    /* API */
    getSurveyResult() {
      this.$store.dispatch('set_isLoadingActive', true);

      surveyService.getSurveyResult(this.surveyId, this.token)
        .then((resp) => (this.surveyResult = resp))
        .catch((err) => alert(err))
        .finally(() => this.$store.dispatch('set_isLoadingActive', false));
    },
    /* Helpers */
    replaceTitleTokens(str) {
      if (!str) return this.surveyName;

      const resp = str
        .replace('<#gender:pelo/pela#>', 'pelo')
        .replace('<#gender:do/da#>', 'do')
        .replace('<#genderLocal:do/da#>', 'do')
        .replace('<#gender:o/a#>', 'o')
        .replace('<#client#>', 'clube');

      return resp;
    },
  },
};
</script>

<style>
.custom-tabs {
  border: 0;
  border-radius: 3px;
  margin-top: 1.25rem;
  padding: 10px;
}

.custom-tabs .ul-tabs.vs-tabs--ul.ul-tabs-left {
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  margin-top: 6px;
}

.custom-tabs .vs-tabs--content {
  padding: 0 !important;
}

.custom-tabs .vs-tabs-position-top.vs-tabs--ul {
  gap: 5px;
}

.custom-tabs .con-ul-tabs .vs-tabs--li:not(.activeChild) button span {
  color: #d6d3d1;
}
</style>
